import React from "react";

import { Router as ReachRouter } from "@reach/router";
import { graphql, StaticQuery } from "gatsby";

import { PrivateRoute } from "./components/auth";
import Layout from "./components/layout/dashboard";

import ForgotPassword from "./pages/forgot_password";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Logout from "./pages/logout";
import ResetPassword from "./pages/reset_password";

import Applications from "./pages/applications";
import Billing from "./pages/billing";
import Account from "./pages/account";
import Tokens from "./pages/tokens";
import NotFoundPage from "./pages/404";
import Demo from "./pages/demos";

import { MDXRenderer } from "gatsby-mdx";

const apiGuideQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          code {
            body
          }
          frontmatter {
            title
          }
          fields {
            slug
          }
          tableOfContents
          headings {
            value
            depth
          }
        }
      }
    }
  }
`;

const MDX = ({ node }) => (
  <Layout key={node.id} path={node.fields.slug}>
    <MDXRenderer>{node.code.body}</MDXRenderer>
  </Layout>
);

class PrivateRouter extends React.Component {
  render() {
    return (
      <StaticQuery
        query={apiGuideQuery}
        render={data => {
          return (
            <ReachRouter>
              <Applications path="/" />
              <Billing path="/billing/" />
              <Tokens path="/tokens/" />
              <Account path="/account/" />
              <Demo path="/demos/" />
              <NotFoundPage default />
              {data.allMdx.edges.map(({ node }) => (
                <MDX node={node} key={node.id} path={node.fields.slug} />
              ))}
            </ReachRouter>
          );
        }}
      />
    );
  }
}

class Router extends React.Component {
  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    return (
      <ReachRouter>
        <Login path="/login/" />
        <Signup path="/signup/" showPlans={false} />
        <Logout path="/logout/" />
        <ForgotPassword path="/forgot_password/" />
        <ResetPassword path="/reset_password/" />

        <PrivateRoute path="/*" component={PrivateRouter} />
        <NotFoundPage default />
      </ReachRouter>
    );
  }
}

export default Router;
